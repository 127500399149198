import React, { useEffect } from "react";

import "./App.css";

function App() {
  const [rwaMarketCap, setRwaMarketCap] = React.useState(50288207);
  const [rwaOnChain, setRwaOnChain] = React.useState(152814283);

  useEffect(() => {
    const rwaMarketCapInterval = setInterval(() => {
      setRwaMarketCap((rwaMarketCap) => rwaMarketCap + 1);
    }, 300);
    const rwaOnChainInterval = setInterval(() => {
      setRwaOnChain((rwaOnChain) => rwaOnChain + 1);
    }, 1000);
    return () => {
      clearInterval(rwaMarketCapInterval);
      clearInterval(rwaOnChainInterval);
    };
  }, []);

  return (
    <div className="app">
      <div className="blobs" />
      <div className="wrapper">
        <div className="container">
          <div className="header">
            <img src="logo.png" className="logo" alt="logo" />
            <div className="button-secondary">Stay tuned</div>
          </div>

          <div className="hero">
            <div className="hero-text">
              Built to <div>Tokenize</div>
              <br />
              The World
            </div>
            <div className="hero-subtext">
              The Mavryk Blockchain powers <div>the next generation of RWA</div>{" "}
              at scale.
            </div>
            <div className="button-primary">Whitepaper</div>
          </div>

          <div className="stats">
            <div className="stats-cell">
              <div className="stats-cell-text">
                ${rwaMarketCap.toLocaleString("en", { useGrouping: true })}
              </div>
              <div className="stats-cell-subtext">
                Current RWA market cap on-chain
              </div>
            </div>
            <div className="stats-cell">
              <div className="stats-cell-text">
                ${rwaOnChain.toLocaleString("en", { useGrouping: true })}
              </div>
              <div className="stats-cell-subtext">Total RWA's on-chain</div>
            </div>
            <div className="stats-cell">
              <div className="stats-cell-text">$16 trillion</div>
              <div className="stats-cell-subtext">in value by 2030</div>
            </div>
          </div>

          <div className="features">
            <div className="section-surtitle">Keen to jump in?</div>
            <div className="section-title">Everything you need</div>
            <div className="section-subtitle">
              There's nothing like a good starting point to get a sense of how
              things work.
            </div>

            <div className="features-first-row card bg-for-users">
              <div className="container-for-users">
                <div className="feature-title">Made for users</div>
                <div className="feature-text">
                  Launch your securities on the leading blockchain for financial
                  services, where you can efficiently and digitally manage the
                  full lifecycle of your financial assets on-chain.
                </div>
              </div>
            </div>

            <div className="features-second-row">
              <div className="card center bg-build">
                <div className="container-build">
                  <div className="feature-title">Build</div>
                  <div className="feature-text">
                    Build or connect a dApp to the Provenance Blockchain
                    ecosystem.
                  </div>
                </div>
              </div>
              <div className="card center bg-validate">
                <div className="container-build">
                  <div className="feature-title">Validate</div>
                  <div className="feature-text">
                    Help govern and validate transactions on the blockchain in
                    exchange for rewards.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="button-primary">Stay Tuned</div>

          <div className="section-comments">
            <div className="card center">
              <div className="comment-photo">
                <img src="dave.png" alt="comment" />
              </div>
              <div className="comment-content">
                Mavryk is by far the best Blockchain for RWA I've ever tried.
              </div>
              <div className="comment-author">Dave Mitchell</div>
            </div>
          </div>

          <div className="section-integrations">
            <div className="section-surtitle">INTEGRATIONS</div>
            <div className="section-title">
              Powering The Digital Commonwealth
            </div>
            <div className="section-subtitle">
              The MAV token is the core of the ecosystem and serves three
              distint purposes: Governance, Staking, & the Treasury.
            </div>

            <div className="integrations-grid">
              <div className="integrations-cell">
                <img src="integration-governance.svg" alt="logo" />
                <div className="integration-title">Governance</div>
                <div className="integration-content">
                  Mavryk features seamless upgrades to ensure our cutting edge
                  technology always remains at the forefront of innovation.
                  Active community governance & participation is a key component
                  of decentralized networks, and at the very core of the Mavryk
                  Network.
                </div>
              </div>
              <div className="integrations-cell">
                <img src="integration-staking.svg" alt="logo" />
                <div className="integration-title">Staking</div>
                <div className="integration-content">
                  Earn rewards for helping to secure the Mavryk Network by
                  easily delegating your tokens' rights to a validator.
                  Validators mint blocks, vote on your behalf, and pay rewards
                  directly to your wallet. Staked tokens always remain liquid in
                  your wallet and are never at risk.
                </div>
              </div>
              <div className="integrations-cell">
                <img src="integration-treasury.svg" alt="logo" />
                <div className="integration-title">Treasury</div>
                <div className="integration-content">
                  The Treasury serves for the continuous advancement of the
                  Mavryk ecosystem, development, growth, and liquidity.
                  Validators democratically control the Treasury transparently
                  on-chain, and it is designed to incentivize DeFi for the next
                  decade.
                </div>
              </div>
            </div>
          </div>

          <div className="section-benefits">
            <div className="section-surtitle">BENEFITS</div>
            <div className="section-title">Benefits on Launching on Mavryk</div>
            <div className="section-subtitle">
              It couldn't be easier to start your project on Mavryk
            </div>

            <div className="benefits-grid">
              <div className="benefit-cell card">
                <img src="benefit-digital.svg" alt="logo" />
                <div className="benefit-title">A Digital Commonwealth</div>
                <div className="benefit-content">
                  An ecosystem of more than 60 active financial institutions,
                  fintechs, and DeFi brands, and $12B+ in supported
                  transactions.
                </div>
              </div>
              <div className="benefit-cell card">
                <img src="benefit-mainstream.svg" alt="logo" />
                <div className="benefit-title">
                  Designed for mainstream users
                </div>
                <div className="benefit-content">
                  An ecosystem of more than 60 active financial institutions,
                  fintechs, and DeFi brands, and $12B+ in supported
                  transactions.
                </div>
              </div>
              <div className="benefit-cell card">
                <img src="benefit-finance.svg" alt="logo" />
                <div className="benefit-title">Democratized Finance</div>
                <div className="benefit-content">
                  An ecosystem of more than 60 active financial institutions,
                  fintechs, and DeFi brands, and $12B+ in supported
                  transactions.
                </div>
              </div>
              <div className="benefit-cell card">
                <img src="benefit-scale.svg" alt="logo" />
                <div className="benefit-title">Efficient at scale</div>
                <div className="benefit-content">
                  An ecosystem of more than 60 active financial institutions,
                  fintechs, and DeFi brands, and $12B+ in supported
                  transactions.
                </div>
              </div>
            </div>
          </div>

          <div className="section-everywhere">
            <div className="section-surtitle">EVERYWHERE</div>
            <div className="section-title">Built for you</div>
            <div className="section-subtitle">
              It couldn't be easier to start your project on Mavryk
            </div>

            <div className="everywhere-buttons">
              <div className="button-active">RWAs</div>
              <div className="button-inactive">DeFi</div>
              <div className="button-inactive">NFT</div>
              <div className="button-inactive">Gaming</div>
            </div>

            <div className="everywhere-cell card">
              <img src="equiteez.png" alt="equiteez" />
              <div className="everywhere-cell-content">
                <div className="everywhere-cell-title">Equiteez</div>
                <div className="everywhere-cell-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Nullam maximus ullamcorper mauris. Aenean blandit porta
                  posuere. Etiam sodales molestie nibh ac dapibus. Nunc tempor
                  volutpat felis, et luctus elit suscipit.
                </div>
                <div className="everywhere-cell-arrow">
                  <div>Next Project</div>
                  <img src="arrow.svg" alt="arrow" />
                </div>
              </div>
            </div>
          </div>

          <div className="section-newsletter">
            <div className="section-surtitle">NEWSLETTER</div>
            <div className="section-title">
              Stay in the loop on the latest from Mavryk Blockchain
            </div>
            <div className="section-subtitle">
              It couldn't be easier to start your project on Mavryk
            </div>

            <div className="newsletter-form">
              <input type="text" placeholder="Enter your email" />
              <div className="button-primary">Subscribe</div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer">
        <div className="footer-container">
          <div className="footer-links">
            <div className="footer-links-section">
              <div>Discover</div>
              <a href="https://google.com">Documentation</a>
              <a href="https://google.com">Marketplace</a>
            </div>
            <div className="footer-links-section">
              <div>Examples</div>
              <a href="https://google.com">Example 1</a>
              <a href="https://google.com">Example 2</a>
            </div>
            <div className="footer-links-section">
              <div>Community</div>
              <a href="https://google.com">Discord</a>
              <a href="https://google.com">Twitter</a>
              <a href="https://google.com">Telegram</a>
            </div>
            <div className="footer-links-section">
              <div>Integrations</div>
              <a href="https://google.com">React</a>
              <a href="https://google.com">API</a>
            </div>
          </div>
          <div className="footer-terms">
            <img src="logo.png" className="logo" alt="logo" />
            <div className="footer-terms-right">
              <a href="https://google.com">Privacy Policy</a>
              <a href="https://google.com">Terms of Use</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
